import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import ParculCriuleni from '../Components/ParculCriuleni';
import DubasariiVechi from './DubasariiVechi';
import GMeniuc from './GMeniuc';
import Hyperion from './Hyperion';
import LiceulSportiv2 from './LiceulSportiv2';
import OrizontBuiucani from './OrizontBuiucani';
import OrizontCiocana from './OrizontCiocana';

class ProiectareExemple extends Component {
    render() {
        return (
            <Container>
                <div class='row  mbg pb-2 text-center'>
                    <h3>Proiectele noastre recente</h3>
                </div>
                <hr className='m-auto' />
                <ParculCriuleni />
                <hr className='m-auto' />
                <OrizontCiocana />
                <hr className='m-auto' />
                <OrizontBuiucani />
                <hr className='m-auto' />
                <Hyperion />
                <hr className='m-auto' />
                <LiceulSportiv2 />
                <hr className='m-auto' />
                <GMeniuc />
                <hr className='m-auto' />
                <DubasariiVechi />
            </Container>
        );
    }
}

export default ProiectareExemple;