import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/pf/OrizontBuiucani1.webp';
import img2 from '../img/pf/OrizontBuiucani2.webp';
import img3 from '../img/pf/OrizontBuiucani3.webp';
import img4 from '../img/pf/OrizontBuiucani4.webp';

class OrizontBuiucani extends Component {
    render() {
        return (
            <Container>
                <div class='row  text-center mbg pt-4 '>
                    <h4>Liceul Teoretic "Orizont", filiala Buiucani, Chișinău</h4>
                </div>
                <div class='row  mbg mvid'>
                    <iframe class="mif" title='OrizontBuiucani' src="https://www.youtube.com/embed/gkZjLbNP0kA?rel=0&showinfo=0"></iframe>
                </div>
                <div class='row  mbg pt-3'>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img1}
                            class="pb-3 img-fluid"
                            alt="OrizontBuiucani"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img2}
                            class="pb-3 img-fluid"
                            alt="OrizontBuiucani"
                        />
                    </div>
                </div>

                <div class='row  mbg pb-3'>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img3}
                            class="pb-3 img-fluid"
                            alt="OrizontBuiucani"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img4}
                            class="pb-3 img-fluid"
                            alt="OrizontBuiucani"
                        />
                    </div>
                </div>
            </Container >
        );
    }
}

export default OrizontBuiucani;