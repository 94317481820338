import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import logo from '../img/logo.webp';
import MapGoogle from './MapGoogle';

class Bottom extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row bg-my py-3'>
                    <div class='col-lg-2 col-md-5 col-sm-10 m-auto text-center'>
                        <img
                            src={logo}
                            height="80"
                            width="80"
                            className="d-inline-block align-center"
                            alt="Logo"
                        />
                        <h5 class='py-3 '>Geosport Group SRL</h5>
                    </div>
                    <div class='d-none d-lg-block col-lg-3 col-xl-3 d-lg-block d-xl-block mblink m-auto'>
                        <Nav.Link as={Link} to="/InstalareIarba">Instalare iarba artificiala</Nav.Link>
                        <Nav.Link as={Link} to="/InstalareTartan">Acoperire din cauciuc</Nav.Link>
                        <Nav.Link as={Link} to="/Proiectare">Servicii de proiectare</Nav.Link>
                        <Nav.Link as={Link} to="/Echipament">Echipament sportiv</Nav.Link>
                    </div>
                    <div class='col-lg-3 col-md-5 col-sm-10 mblink m-auto text-center'>
                        <p>(+373) 787 53 333</p>
                        <a href="mailto:info@geosport.md">info@geosport.md</a>
                        <p class='pt-3'>mun. Chisinau, str.Vasile Lupu 87/4</p>
                    </div>
                    <div class='col-lg-4 col-md-10 col-sm-10 pe-3 m-auto'>
                        <MapGoogle />
                    </div>
                </div>

            </Container>
        );
    }
}

export default Bottom;