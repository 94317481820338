import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import img2 from '../img/2.webp';
import img3 from '../img/3.webp';

class Home2 extends Component {
    render() {
        return (
            <Container>
                <div class='row  mbg pt-5'>
                    <div class='col-lg-10 col-md-12'>
                        <h1 class='text-center py-3 '>Geosport Group este un furnizor de încredere de suprafețe sportive.</h1>
                        <p>
                            Venim cu tehnologii noi în construcția și restabilirea suprafețelor de sport.
                            În timpul activității noastre, am instalat peste 900 de mii de metri pătrați de
                            acoperiri din iarbă artificială si suprafețe din cauciuc pentru terenuri sport si
                            locuri de joacă și recreere.
                        </p>
                    </div>
                </div>
                <div class='row  mt-4 mbg '>
                    <div class=' col-lg-5 col-md-6  '>
                        <hr />
                        <p>
                            În realizarea proiectelor “Geosport Group” folosește numai materiale de înaltă
                            calitate de la furnizori Europeni. Partenerii fiind lideri Europeni cu certificări
                            si omologate UEFA, FIFA, ITF, FIBA, IVF.
                        </p>
                        <hr />
                    </div>
                    <div class=' col-lg-5 col-md-6 col-sm-10  '>
                        <img
                            width={540}
                            height={198}
                            src={img2}
                            class="par1 img-fluid"
                            alt="Ball"
                        />
                    </div>
                </div>
                <div class='row  mbg pb-5 flex-column-reverse flex-md-row'>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={540}
                            height={198}
                            src={img3}
                            class="par1 img-fluid"
                            alt="Paper"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6'>
                        <hr />
                        <p>
                            Propriul departament de proiectări în construcții, terenuri de sport permite
                            indeplinirea ciclul complet - de la proiectare pina la construcția si dotare cu
                            echipament/utilaj necesar.
                        </p>
                        <hr />
                    </div>

                </div>
            </Container>
        );
    }
}

export default Home2;