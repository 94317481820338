import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import img5 from '../img/lf/h3.1.webp';
import img6 from '../img/lf/h3.2.webp';
import img1 from '../img/lf/h1.1.webp';
import img2 from '../img/lf/h1.2.webp';
import img3 from '../img/lf/h2.1.webp';
import img4 from '../img/lf/h2.2.webp';
import img7 from '../img/lf/h4.1.webp';
import img8 from '../img/lf/h4.2.webp';
import img9 from '../img/lf/v1.webp';
import img10 from '../img/lf/v2.webp';
import img11 from '../img/lf/v3.webp';
import img12 from '../img/lf/v4.webp';
import img13 from '../img/lf/h5.1.webp';
import img14 from '../img/lf/h5.2.webp';
import img15 from '../img/lf/h6.1.webp';
import img16 from '../img/lf/h6.2.webp';


class InstalareIarbaExemple extends Component {
    render() {
        return (
            <Container>
                <div class='row  mbg pb-3 text-center'>
                    <h4>Exemple de lucrari recent finisate</h4>
                </div>
                {/* ---------- 1 H ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img5} //img/lf/h3.1.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img6} //img/lf/h3.2.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                </div>
                {/* ---------- 2 H ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img1} //img/lf/h1.1.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img2} //img/lf/h1.2.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                </div>
                {/* ---------- 1 V ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={667}
                            src={img9} //img/lf/v1.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={667}
                            src={img10} //img/lf/v2.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                </div>
                {/* ---------- 3 H ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img3} //img/lf/h2.1.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img4} //img/lf/h2.2.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                </div>
                {/* ---------- 4 H ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img7} //img/lf/h4.1.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img8} //img/lf/h4.2.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                </div>
                {/* ---------- 2 V ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={667}
                            src={img11} //img/lf/v3.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={667}
                            src={img12} //img/lf/v4.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                </div>
                {/* ---------- 5 H ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img13} //img/lf/h5.1.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img14} //img/lf/h5.2.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                </div>
                {/* ---------- 6 H ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={521}
                            height={326}
                            src={img15} //img/lf/h6.1.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={521}
                            height={326}
                            src={img16} //img/lf/h6.2.webp';
                            class="py-3 img-fluid"
                            alt="instalation-example"
                        />
                    </div>
                </div>
            </Container>
        );
    }
}

export default InstalareIarbaExemple;