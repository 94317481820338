import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/pf/Criuleni1.webp';
import img2 from '../img/pf/Criuleni2.webp';
import img3 from '../img/pf/Criuleni3.webp';
import img4 from '../img/pf/Criuleni4.webp';

class ParculCriuleni extends Component {
    render() {
        return (
            <Container>
                <div class='row  text-center mbg pt-4 '>
                    <h4>Parcul central, or Criuleni</h4>
                </div>

                <div class='row  mbg mvid'>

                    <iframe class="mif" title='ParculCriuleni' src="https://www.youtube.com/embed/PV5jiLTdpzk?rel=0&showinfo=0"></iframe>
                    {/* <iframe  url="https://www.youtube.com/embed/PV5jiLTdpzk" */}


                </div>

                <div class='row  mbg pt-3'>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img1}
                            class="pb-3 img-fluid"
                            alt="projectCriuleni"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img2}
                            class="pb-3 img-fluid"
                            alt="projectCriuleni"
                        />
                    </div>
                </div>

                <div class='row  mbg pb-3'>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img3}
                            class="pb-3 img-fluid"
                            alt="projectCriuleni"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img4}
                            class="pb-3 img-fluid"
                            alt="projectCriuleni"
                        />
                    </div>
                </div>
            </Container >
        );
    }
}

export default ParculCriuleni;