import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/t1.webp';
import img2 from '../img/t2.webp';
import img3 from '../img/t3.webp';
import img4 from '../img/t4.webp';
import img5 from '../img/t5.webp';
import img6 from '../img/t6.webp';

class InstalareTartan1 extends Component {
    render() {
        return (
            <Container>
                <div class='row  mbg ptop2 pb-5 '>
                    <h2 class=' text-center'>Acoperirea suprafetelor cu tartan - turnătură din granule de cauciuc</h2>
                    <div class='col-lg-5 col-md-6'>
                        <hr />
                        <p>
                            Cauciucul EPDM este un tip de cauciuc sintetic care este utilizat în multe aplicații. EPDM
                            este fabricat din etilenă, propilenă și un comonomer dienic care permite reticulare prin
                            vulcanizare cu sulf.
                        </p>
                        <p>
                            Cauciuc crează o suprafață de siguranță anti-alunecare. Este folosit ca suprafață pentru
                            terenuri multisport, pentru terenuri de joacă — asigură amortizarea in cazul căderii.
                        </p>
                        <hr />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 px-5 pt-3'>
                        <img
                            width={400}
                            height={400}
                            src={img1}
                            class="py-3 img-fluid"
                            alt="EPDM"
                        />
                    </div>
                </div>
                <div class='row  mbg pb-5 text-center'>
                    <div class='col-lg-10 col-md-10'>
                        <h5>Există o varietate de culori a granulelor de cauciuc EPDM</h5>
                    </div>
                    <div class='col-lg-10 col-md-10'>
                        <img
                            width={487}
                            height={399}
                            src={img2}
                            class="py-3 img-fluid"
                            alt="sportChamp"
                        />
                    </div>
                </div>
                <div class='row  mbg pb-5 '>
                    <h4 class=' text-center'>Acoperirea suprafetelor cu tartan - turnătură din granule de cauciuc</h4>
                    <div class='col-lg-5 col-md-6'>
                        <hr />
                        <p>
                            Folosind echipament specializat de mixare se amesteca doua componente principale — granule de
                            cauciuc si adeziv special, rezistent la UV.
                        </p>
                        <hr />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10  px-5'>
                        <img
                            width={300}
                            height={254}
                            src={img3}
                            class="py-3 img-fluid"
                            alt="EPDM mix"
                        />
                    </div>
                </div>
                <hr />
                <div class='row  mbg  pb-5 '>
                    <h4 class=' text-center'>Acoperirea suprafetelor cu tartan - turnătură din granule de cauciuc</h4>
                    <div class='col-lg-5 col-md-6 col-sm-10 px-5'>
                        <img
                            width={400}
                            height={220}
                            src={img4}
                            class="py-3 img-fluid"
                            alt="EPDM aplication"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6'>
                        <hr />
                        <p>
                            Aplicarea mixului de granule din cauciuc cu adeziv direct pe suprafata de beton, preacoperit cu
                            un grund special, pentru adgezie inalta a straturilor.
                        </p>
                        <hr />
                    </div>
                </div>

                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 text-end'>
                        <img
                            width={666}
                            height={499}
                            src={img5}
                            class="py-3 img-fluid"
                            alt="SMG application"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 text-start'>
                        <img
                            width={666}
                            height={499}
                            src={img6}
                            class="py-3 img-fluid"
                            alt="SMG application"
                        />
                    </div>
                </div>
            </Container>
        );
    }
}

export default InstalareTartan1;