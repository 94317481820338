import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/p1.webp';
import img2 from '../img/p2.webp';
import img3 from '../img/p3.webp';
import img4 from '../img/p4.webp';
import img5 from '../img/p5.webp';

class Proiectare1 extends Component {
    render() {
        return (
            <Container>
                <div class='row  mbg ptop2 pb-5 '>
                    <h2 class=' text-center'>Servicii de proiectare</h2>
                    <div class='col-lg-5 col-md-6'>
                        <hr />
                        <p>
                            Geosport Group oferta servicii de elaborare a documentatiei de proiect, inclusiv schita de proiect
                            si a devizului de cheltuieli.
                        </p>
                        <hr />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 px-5'>
                        <img
                            width={570}
                            height={480}
                            src={img1}
                            class="py-3 img-fluid"
                            alt="grass"
                        />
                    </div>
                </div>

                <div class='row  mbg pb-5 flex-column-reverse flex-md-row'>
                    <div class='col-lg-5 col-md-6 col-sm-10 px-5'>
                        <img
                            width={533}
                            height={179}
                            src={img2}
                            class="pb-3  img-fluid"
                            alt="grass"
                        />
                        <img
                            width={533}
                            height={179}
                            src={img3}
                            class=" img-fluid"
                            alt="grass"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6'>
                        <p class='text-center'><b>Proiectarea terenurilor sport, spatiilor de joaca</b></p>
                        <p>
                            - Proiectare terenuri de fotbal, terenuri multifuncționale, cu gazon sintetic sau suprafețe din
                            tartan, inclusiv proiecte plane deschise pentru cultura fizică și sport (CP C.01.09:2017)
                        </p>
                        <p>
                            - Proiectare locuri de joacă, cu respectarea normelor privind siguranța copiilor, inclusiv
                            accesibilitatea pentru persoane cu dezabilități.
                        </p>
                        <p>
                            - Proiectare locuri de recreere și sport in spații deschise si închise, echipamente Street workout,
                            skate-parcuri și multe altele.
                        </p>
                    </div>
                </div>
                <div class='row  mbg pb-5 '>
                    <div class='col-lg-5 col-md-6'>
                        <p class='text-center'><b>Proiectare arhitectură peisageră</b></p>
                        <p>
                            - Amenajări teritoriu pentru Curți, Școli, Grădinițe, Obiective culturale, Complexe locative,
                            Instituții medicale (Spitale, Clinici, Profilactorii).
                        </p>
                        <p>
                            - Parcuri, Scuaruri, Grădini publice, Grădini private, Bulevarde, Faleze, Spații acvatice decorative.
                        </p>
                        <p>
                            - Reconstrucții și reamenajări Parcuri și Grădini istorice, și de patrimoniu, amenajări
                            teritoriu obiective de cult (Biserici, Mănăstiri, Schituri).
                        </p>
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 px-5'>
                        <img
                            width={533}
                            height={195}
                            src={img4}
                            class="pb-3 img-fluid"
                            alt="grass"
                        />
                        <img
                            width={533}
                            height={195}
                            src={img5}
                            class=" img-fluid"
                            alt="grass"
                        />
                    </div>
                </div>
                <hr />
            </Container>
        );
    }
}

export default Proiectare1;