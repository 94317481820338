import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/lf/t1.webp';
import img2 from '../img/lf/t2.webp';
import img3 from '../img/lf/t3.webp';
import img4 from '../img/lf/t4.webp';
import img5 from '../img/lf/tv1.webp';
import img6 from '../img/lf/tv2.webp';
import img7 from '../img/lf/tbt.webp';

class InstalareTartanExemple extends Component {
    render() {
        return (
            <Container>
                <div class='row  mbg pb-3 text-center'>
                    <h4>Exemple de lucrari recent finisate</h4>
                </div>
                {/* ---------- 1 H ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 text-end'>
                        <img
                            width={500}
                            height={317}
                            src={img1}
                            class="py-3 img-fluid"
                            alt="example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 text-start'>
                        <img
                            width={500}
                            height={317}
                            src={img2}
                            class="py-3 img-fluid"
                            alt="example"
                        />
                    </div>
                </div>
                {/* ---------- 2 H ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 text-end'>
                        <img
                            width={500}
                            height={317}
                            src={img3}
                            class="py-3 img-fluid"
                            alt="example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 text-start'>
                        <img
                            width={500}
                            height={317}
                            src={img4}
                            class="py-3 img-fluid"
                            alt="example"
                        />
                    </div>
                </div>
                {/* ---------- 1 V ---------- */}
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 text-end'>
                        <img
                            width={500}
                            height={667}
                            src={img5}
                            class="py-3 img-fluid"
                            alt="example"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 text-start'>
                        <img
                            width={500}
                            height={667}
                            src={img6}
                            class="py-3 img-fluid"
                            alt="example"
                        />
                    </div>
                </div>
                {/* ---------- V ---------- */}
                <div class='row  mbg  mbottom text-center'>
                    <div class='col-lg-10 col-md-10'>
                        <img
                            width={1010}
                            height={493}
                            src={img7}
                            class="py-3 img-fluid"
                            alt="example"
                        />
                    </div>
                </div>
            </Container>
        );
    }
}

export default InstalareTartanExemple;