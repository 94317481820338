import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img1 from '../img/ec/echipament1.webp';
import img2 from '../img/ec/echipament2.webp';
import img3 from '../img/ec/echipament3.webp';
import img4 from '../img/ec/echipament4.webp';
import img5 from '../img/ec/echipament5.webp';
import img6 from '../img/ec/echipament6.webp';
import img7 from '../img/ec/echipament7.webp';
import img8 from '../img/ec/echipament8.webp';

class Echipament extends Component {
    render() {
        return (
            <Container>
                <div class='row  mbg ptop2 pb-5 '>
                    <h2 class=' text-center'>Echipament Sportiv</h2>
                    <p class='pt-3 text-center'>Geosport este importator a echipamentliui sportiv de calitate</p>
                </div>
                <div class='row  mbg pb-5 mw px-3'>
                    <ul>
                        <li>Porti Fotbal, Handbal</li>
                        <li>Piloane Baschet, Volei, Tenis etc.</li>
                        <li>Plasa pentru porti fotbal, volei, tenis, baschet etc.</li>
                        <li>Plasa de protectie</li>
                        <li>Inventar sportiv - mingi, palete si mlite alte</li>
                    </ul>
                </div>
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img1} //img/lf/h3.1.webp';
                            class="py-3 img-fluid"
                            alt="echipament"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img2} //img/lf/h3.2.webp';
                            class="py-3 img-fluid"
                            alt="echiapment"
                        />
                    </div>
                </div>
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img3} //img/lf/h3.1.webp';
                            class="py-3 img-fluid"
                            alt="echipament"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img4} //img/lf/h3.2.webp';
                            class="py-3 img-fluid"
                            alt="echiapment"
                        />
                    </div>
                </div>
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={687}
                            src={img5} //img/lf/h3.1.webp';
                            class="py-3 img-fluid"
                            alt="echipament"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={687}
                            src={img6} //img/lf/h3.2.webp';
                            class="py-3 img-fluid"
                            alt="echiapment"
                        />
                    </div>
                </div>
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img7} //img/lf/h3.1.webp';
                            class="py-3 img-fluid"
                            alt="echipament"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img
                            width={515}
                            height={317}
                            src={img8} //img/lf/h3.2.webp';
                            class="py-3 img-fluid"
                            alt="echiapment"
                        />
                    </div>
                </div>
            </Container>
        );
    }
}

export default Echipament;