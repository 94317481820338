import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/ins-iarb-1.webp';
import img2 from '../img/ins-iarb-2.webp';
import img3 from '../img/ins-iarb-3.webp';
import img4 from '../img/ins-iarb-4.webp';
import img5 from '../img/ins-iarb-5.webp';

class InstalareIarba1 extends Component {
    render() {
        return (
            <Container>
                <div class='row  mbg ptop2 pb-5 '>
                    <h2 class=' text-center'>Lucrări de instalare a covorului din iarbă artificială</h2>
                    <div class='col-lg-5 col-md-6'>
                        <hr />
                        <p>
                            Iarbă artificială se numește suprafața gazonului realizată din
                            materiale plastice. Aspectul său, în special în artefacte mai recente,
                            este foarte asemănător cu iarbă naturală . Se folosește de obicei pentru
                            construcția de terenuri de sport , dar și în case sau structuri comerciale
                            pentru economia și ușurința de gestionare datorită costurilor reduse de
                            întreținere.
                        </p>
                        <hr />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 px-5'>
                        <img
                            width={570}
                            height={480}
                            src={img1}
                            class="py-3 img-fluid"
                            alt="grass"
                        />
                    </div>
                </div>
                <div class='row  mbg pb-5 text-center'>
                    <div class='col-lg-10 col-md-10'>
                        <h5>Există o varietate de tipuri de iarbă artificială, care diferă prin forma părului, înălțimea, densitatea, precum și scopul utilizării.</h5>
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-11 px-5'>
                        <img
                            width={533}
                            height={195}
                            src={img2}
                            class="py-3 img-fluid"
                            alt="grass1"
                        />
                        <img
                            width={533}
                            height={195}
                            src={img4}
                            class="py-3 img-fluid"
                            alt="grass2"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-11 px-5'>
                        <img
                            width={533}
                            height={195}
                            src={img3}
                            class="py-3 img-fluid"
                            alt="grass3"
                        />
                        <img
                            width={533}
                            height={195}
                            src={img5}
                            class="py-3  img-fluid"
                            alt="grass4"
                        />
                    </div>
                    <div class='col-lg-10 col-md-10'>
                        <h5>
                            Beneficiul principal a utilizării gazonului din iarbă artificială - este 
                            rezistentă la utilizări intense, cum ar fi în sport, și nu necesită irigare 
                            sau tundere.</h5>
                    </div>
                </div>
                <hr />
            </Container>
        );
    }
}

export default InstalareIarba1;