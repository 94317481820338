import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';
import Top from '../src/Components/Top'


function App() {
  return (
    <div class='mbg'>
      <Top />
    </div>
  );
}

export default App;
