import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import img1 from '../img/4.webp';
import img2 from '../img/5.webp';
import img3 from '../img/6.webp';
import img4 from '../img/7.webp';

class Home3 extends Component {
    render() {
        return (
            <Container>
                <hr />
                <div class='row  mbg pt-5 d-flex '>
                    <div class='col-lg-5 col-md-6 col-sm-10 d-flex justify-content: center'>
                        <img
                            width={550}
                            height={412}
                            src={img1}
                            class="par1 img-fluid"
                            alt="grass-on-field"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6'>
                        <h3 class=' py-3 text-start'>Lucrări de instalare a covorului din iarbă artificială</h3>
                        <p class='text-start'>Instalarea covorului din iarba artificiala pe terenuri fotbal, multisport, spatii de joaca</p>
                        <p class='text-start'>Amenajări terenuri de fotbal</p>
                        <p class='text-start'>Acoperire cu gazon artificial a terenurilor sportive, spatii de agrement.</p>
                        <div class='text-center'>
                            <Button as={Link} to="/InstalareIarba" className="mbtn" size="lg">
                                DETALII
                            </Button>
                        </div>
                    </div>
                </div>

                <hr />
                <div class='row  mbg pt-5  flex-column-reverse flex-md-row'>
                    <div class='col-lg-5 col-md-6'>
                        <h3 class=' py-3 text-start'>Lucrări de turnatura de cauciuc (tartan)</h3>
                        <p class='text-start'>Realizarea lucrărilor de amenajare a terenurilor sportive cu acoperire artificială cu utilaj specializat de ultimă performanță</p>
                        <p class='text-start'>Amenajări teritoriu pentru Curți, Școli, Grădinițe, Complexe locative</p>
                        <p class='text-start'>Parcuri, Scuaruri, Grădini publice, Grădini private.</p>
                        <div class='text-center'>
                            <Button as={Link} to="/InstalareTartan" className="mbtn" size="lg">
                                DETALII
                            </Button>
                        </div>
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 justify-content: center'>
                        <img
                            width={550}
                            height={412}
                            src={img2}
                            class="par1 img-fluid"
                            alt="tartan"
                        />
                    </div>
                </div>

                <hr />
                <div class='row  mbg pt-5 d-flex '>
                    <div class='col-lg-5 col-md-6 col-sm-10 d-flex justify-content: center'>
                        <img
                            width={550}
                            height={412}
                            src={img3}
                            class="par1 img-fluid"
                            alt="proiect"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6'>
                        <h3 class=' py-3 text-start'>Servicii de proiectare a terenurilor sport, spatiilor de joaca</h3>
                        <p class='text-start'>Proiectare terenuri de fotbal, terenuri multifuncționale, cu gazon sintetic sau suprafețe din tartan, inclusiv proiecte plane deschise pentru cultura fizică și sport (CP C.01.09:2017)</p>
                        <p class='text-start'>Proiectare locuri de joacă, cu respectarea normelor privind siguranța copiilor, inclusiv accesibilitatea pentru persoane cu dezabilități.</p>
                        <p class='text-start'>Proiectare locuri de recreere și sport in spații deschise si închise, echipamente Street workout, skate-parcuri și multe altele.</p>
                        <div class='text-center'>
                            <Button as={Link} to="/Proiectare" className="mbtn" size="lg">
                                DETALII
                            </Button>
                        </div>
                    </div>
                </div>

                <hr />
                <div class='row  mbg py-5  flex-column-reverse flex-md-row'>
                    <div class='col-lg-5 col-md-6'>
                        <h3 class=' py-3 text-start'>Echipament sportiv</h3>
                        <p class='text-start'>Porti Fotbal, Handbal</p>
                        <p class='text-start'>Piloane Baschet, Volei, Tenis etc</p>
                        <p class='text-start'>Plasa de protectie</p>
                        <p class='text-start'>Inventar sportiv - mingi, palete si multe alte.</p>
                        <div class='text-center'>
                            <Button as={Link} to="/Echipament" className="mbtn" size="lg">
                                DETALII
                            </Button>
                        </div>
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 justify-content: center'>
                        <img
                            width={550}
                            height={412}
                            src={img4}
                            class="par1 img-fluid"
                            alt="baschet-plate"
                        />
                    </div>
                </div>
            </Container>
        );
    }
}

export default Home3;