import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/ins-iarb-6.webp';
import img2 from '../img/ins-iarb-7.webp';
import img3 from '../img/ins-iarb-8.webp';
import img4 from '../img/ins-iarb-9.webp';
import img5 from '../img/ins-iarb-10.webp';
import img6 from '../img/ins-iarb-11.webp';

class InstalareIarba2 extends Component {
    render() {
        return (
            <Container>
                <div class='row  mbg pb-3 text-center'>
                    <div class='col-lg-10 col-md-10'>
                        <h4> Compania Geosport execută lucrări de instalare a covorului din iarbă artificială cu respectarea reglementărilor internationale.</h4>
                    </div>
                    <div class='col-lg-10 col-md-10'>
                        <img
                            width={600}
                            height={441}
                            src={img1}
                            class="py-3 img-fluid"
                            alt="sportChamp"
                        />
                    </div>
                </div>

                <div class='row  mbg pb-3 text-center'>
                    <p>1. pregatirea si nivelarea solului</p>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={540}
                            height={405}
                            src={img2}
                            class="py-3 img-fluid"
                            alt="prepare1"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={540}
                            height={405}
                            src={img3}
                            class="py-3 img-fluid"
                            alt="prepare2"
                        />
                    </div>
                </div>

                <div class='row  mbg pb-3 text-center'>
                    <p>2. instalarea covorului</p>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={540}
                            height={405}
                            src={img4}
                            class="py-3 img-fluid"
                            alt="prepare1"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={540}
                            height={405}
                            src={img5}
                            class="py-3 img-fluid"
                            alt="prepare2"
                        />
                    </div>
                </div>

                <div class='row  mbg pb-3 text-center'>
                    <p>3. infiltrarea covorului cu nisip si granule de cauciuc</p>
                    <div class='col-lg-10 col-md-10 '>
                        <img
                            width={1080}
                            height={399}
                            src={img6}
                            class="py-3 img-fluid"
                            alt="prepare1"
                        />
                    </div>
                </div>
                <hr />
            </Container >
        );
    }
}

export default InstalareIarba2;