import React, { Component } from 'react';
import InstalareTartan1 from '../Components/InstalareTartan1';
import InstalareTartanExemple from '../Components/InstalareTartanExemple';
import Bottom from '../Components/Bottom';
import GoToTop from '../Components/GoToTop';


class InstalareTartan extends Component {
    render() {
        return (
            <>
                <InstalareTartan1 />
                <InstalareTartanExemple />
                <Bottom />
                <GoToTop />
            </>
        );
    }
}

export default InstalareTartan;