import React, { Component } from 'react';
import Bottom from '../Components/Bottom';
import GoToTop from '../Components/GoToTop';
import Proiectare1 from '../Components/Proiectare1';
import ProiectareExemple from '../Components/ProiectareExemple';


class Proiectare extends Component {
    render() {
        return (
            <>
                <Proiectare1 />
                <ProiectareExemple />
                <Bottom />
                <GoToTop />
            </>
        );
    }
}

export default Proiectare;