import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/1.webp';

class Home1 extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row mbg ptop text-center'>
                <img
                            width={1800}
                            height={1013}
                            src={img1}
                            class="par1 img-fluid"
                            alt="Geosport"
                        />
                </div>

            </Container>
        );
    }
}

export default Home1;